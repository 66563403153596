import React from 'react';
import { Link } from 'react-router-dom';
import { redirectToURL } from './pages/redirectUtils';

function CardItem({ src, text, label, path, repositoryUrl, displayTemperature, minTemp, maxTemp }) {
  const handleCardClick = () => {
    if (repositoryUrl) {
      redirectToURL(repositoryUrl);
    }
  };

  const renderTemperature = displayTemperature && (
    <div>
      <p>Min Temp: {minTemp}</p>
      <p>Max Temp: {maxTemp}</p>
    </div>
  );

  return (
    <li className='cards__item' onClick={handleCardClick}>
      {path ? (
        <Link className='cards__item__link' to={path}>
          <figure className='cards__item__pic-wrap' data-category={label}>
            <img className='cards__item__img' alt={text} src={src} />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{text}</h5>
            {renderTemperature}
          </div>
        </Link>
      ) : (
        <div className='cards__item__link'>
          <figure className='cards__item__pic-wrap' data-category={label}>
            <img className='cards__item__img' alt={text} src={src} />
          </figure>
          <div className='cards__item__info'>
            <h5 className='cards__item__text'>{text}</h5>
            {displayTemperature && (
              <div>
                <p>{minTemp}° / {maxTemp}°C</p>
              </div>
            )}
          </div>
        </div>
      )}
    </li>
  );
}

export default CardItem;
