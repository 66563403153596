import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Doc from './components/pages/Doc'
import Projects from './components/pages/Projects';
import Tools from './components/Tools';
import SarcasmText from './components/pages/SarcasmText';
import WeatherComponent from './components/pages/WeatherComponent';


function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/doc' component={Doc} />    
          <Route path='/projects' component={Projects} />     
          <Route path='/tools' component={Tools} />        
          <Route path='/sarcasm' component={SarcasmText} />
          <Route path='/weather' component={WeatherComponent} /> 
   
        </Switch>
      </Router>
    </>
  );
}

export default App;
