import React from 'react';
import '../Cards.css';
import CardItem from '../CardItem';


function Tools() {
  return (
    <div className='cards'>
      <h1>Tools!</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            {/* Adjust the import path for the image */}
            <CardItem
              src={require('../../images/comic_book_guy.webp')} // Adjust the import path here
              text='Best Sarcasm Text Generator Ever'
              label='SaRcAsm'
              path='/sarcasm'
            />
            <CardItem
              src={require('../../images/happy-cloud-lowres.webp')}
              text='Weather Report'
              label='Weather'
              path='/weather'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Tools;
