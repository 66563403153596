import React, { useState } from 'react';
// import '../../App.css';
import '../Tools.css';
import Footer from '../Footer';


const SarcasmTextGenerator = () => {
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleKeyPress = (e) => {
    // Check if the key pressed is Enter (key code 13)
    if (e.key === 'Enter') {
      generateSarcasmText();
    }
  };

  const generateSarcasmText = () => {
    // You can implement your sarcasm generation logic here
    // For simplicity, let's just toggle the case of each character in the input text
    const sarcasticText = inputText
      .split('')
      .map((char, index) => (index % 2 === 0 ? char.toUpperCase() : char.toLowerCase()))
      .join('');
    setOutputText(sarcasticText);
    setInputText('');
  };

  return (
    <div>
      <div className="tool-container">
        <h1>SaRcAsM TeXt gEnErAtOr</h1>
        <label className="input-label">
          Enter Text:
          <br />
          <input
            type="text"
            value={inputText}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
        </label>
        <br />
        <button onClick={generateSarcasmText}>Generate Sarcasm</button>
        <br />
        <label>
          Sarcasm Output:
          <div>{outputText}</div>
        </label>
      </div>
      <Footer />
    </div>
  );
};

export default SarcasmTextGenerator;