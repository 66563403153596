// Navbar.jsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import { redirectToURL } from './pages/redirectUtils';

function Navbar() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
        <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
        <img src={require('../images/logo-background-removed-gradient-dark.png')} alt="dp Logo" className="navbar-logo" />
        </Link>

          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <li className='nav-item'>
              <Link to='/projects' className='nav-links' onClick={closeMobileMenu}>
                Projects
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={() => { closeMobileMenu(); redirectToURL('https://foundry.diegopisciotta.com'); }}>
                Foundry
              </Link>
            </li>
            <li className='nav-item'>
              <Link to='/tools' className='nav-links' onClick={closeMobileMenu}>
                Tools
              </Link>
            </li>
          </ul>
          <div className='menu-icon' onClick={handleClick}></div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
