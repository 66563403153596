import React from 'react';
import './Doc.css';
import '../../App.css';
import Footer from '../Footer';

export default function Doc() {
  return (
    <div>
      <img
        src={require('../../images/doc_low_res.png')}
        alt='Doc'
        className='doc-image'
      />
      <Footer />
    </div>
  );
}
