import React from 'react';
// import '../../App.css';
import ToolsComponents from './pages/Tools';
import Footer from './Footer';


function Tools() {
    return (
      <>
        <ToolsComponents />
        <Footer />
      </>
    );
  }
  
  export default Tools;