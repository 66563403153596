import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Cards.css';
import CardItem from '../CardItem';
import Footer from '../Footer';

// Function to get the appropriate weather image source
const getWeatherImage = (description) => {
  const lowercaseDescription = description.toLowerCase();

  const weatherImageMap = {
    'clear': require('../../images/weather/clear.jpg'),
    'rain': require('../../images/weather/light rain.jpg'),
    'cloudy': require('../../images/weather/cloudy.jpg'),
    'snow': require('../../images/weather/snow.jpg'),
    'fog': require('../../images/weather/fog.jpg'),
  };

  for (const key in weatherImageMap) {
    if (lowercaseDescription.includes(key)) {
      return weatherImageMap[key];
    }
  }

  return require('../../images/weather/default.jpg');
};

function Cards() {
  const [forecastData, setForecastData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchWeatherData = async () => {
      try {
        const position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });

        const { latitude, longitude } = position.coords;
        const API_KEY = 'f52705469714fd25520b81f5355187bd';
        const response = await axios.get(`https://api.openweathermap.org/data/2.5/forecast?lat=${latitude}&lon=${longitude}&appid=${API_KEY}&units=metric`);
        
        const dailyForecasts = response.data.list.reduce((acc, forecast) => {
          const date = new Date(forecast.dt_txt.split(' ')[0]);
          const dayOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'][date.getDay()];

          if (!acc[dayOfWeek]) {
            acc[dayOfWeek] = {
              dayOfWeek,
              minTemp: forecast.main.temp_min,
              maxTemp: forecast.main.temp_max,
              description: forecast.weather[0].description,
              weatherImageSrc: getWeatherImage(forecast.weather[0].description),
            };
          } else {
            acc[dayOfWeek].minTemp = Math.min(acc[dayOfWeek].minTemp, forecast.main.temp_min);
            acc[dayOfWeek].maxTemp = Math.max(acc[dayOfWeek].maxTemp, forecast.main.temp_max);
          }

          return acc;
        }, {});

        const dailyForecastArray = Object.values(dailyForecasts);

        setForecastData(dailyForecastArray);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching weather data:', error);
        setLoading(false);
      }
    };

    fetchWeatherData();
  }, []);

  return (
    <div>
      <div className='weather'>
        <h1>Weather Forecast</h1>
        <div className='cards__container'>
          <div className='cards__wrapper'>
            <ul className='cards__items'>
              {loading ? (
                <p>Loading weather data...</p>
              ) : (
                forecastData.map((forecast, index) => (
                  <CardItem
                    key={index}
                    src={forecast.weatherImageSrc}
                    text={`${forecast.dayOfWeek}`}
                    label={forecast.description}
                    displayTemperature={true}
                    minTemp={Math.round(forecast.minTemp)}
                    maxTemp={Math.round(forecast.maxTemp)}
                  />
                ))
              )}
            </ul>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Cards;
