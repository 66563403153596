import React from 'react';
import './Footer.css';

function Footer() {
  return (
    <div className='footer-container'>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Contact Me</h2>
            {/* <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>Buy Me A Coffee</a> */}
          </div>
        </div>
      </div>
      <section class='footer-link-items'>
        <div class='social-media-wrap'>
          <div class='social-icons'>
            <a
              class='social-icon-link github'
              href='https://github.com/Daegybyte'
              target='_blank'
              aria-label='Github'
              rel='noopener noreferrer'
            >
              <i class='fab fa-github' />
            </a>
            <a
              class='social-icon-link linkedin'
              href='https://www.linkedin.com/in/diegopisciotta/'
              target='_blank'
              aria-label='LinkedIn'
              rel='noopener noreferrer'
            >
              <i class='fab fa-linkedin' />
            </a>
            <a
              className='social-icon-link email'
              href='mailto:email.diegopisciotta@gmail.com'
              aria-label='Email'
              // comment
            >
              <i className='fas fa-envelope' />
            </a>
          </div>
        </div>
        <a href='https://www.youtube.com/watch?v=dQw4w9WgXcQ'>Buy Me A Coffee</a>

      </section>
      <small class='website-rights'>Diego Pisciotta © 2024</small>
      <small class='website-rights'>Powered By React | Cloudflare | Coffee</small>
    </div>
  );
}

export default Footer;
