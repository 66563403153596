import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>Check Out My Projects!</h1>
      <h3>Fun Fact: One of them is this website!</h3>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src={require('../images/distarb.webp')}
              text='Masters Degree Capstone Project'
              label='Distance Arbitrage'
              repositoryUrl='https://github.com/Daegybyte/website_projects/tree/main/distance_arbitrage'
              path='projects'
            />
            <CardItem
              src={require('../images/lifestyle_cropped.webp')}
              text='A Fitness Tracker App Built From The Ground Up For Android'
              label='Lifestyle App'
              repositoryUrl='https://github.com/Daegybyte/website_projects/tree/main/Lifestyle_App'
              path='projects'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src={require('../images/space-invaders.svg').default}
              text='Plotting The Location Of Reported UFOs Over 50 Years'
              label='UFO Tracker'
              repositoryUrl='https://github.com/Daegybyte/website_projects/tree/main/ufo_reports'
              path='projects'
            />
            <CardItem
              src={require('../images/robot_computer.webp')}
              text='A Fully Functional Turing Complete Language'
              label='MSD Script'
              repositoryUrl='https://github.com/Daegybyte/website_projects/tree/main/msd_script'
              path='projects'
            />
            <CardItem
              src={require('../images/d20.svg').default} // Ensure .default is added
              text='A Brief Description of the Foundry Link'
              label='Foundry'
              path='projects'
              repositoryUrl='https://github.com/Daegybyte/website_projects/blob/main/foundry.md'
            />

            <CardItem
              src={require('../images/doc_low_res.webp')}
              text='Not A Project, Just A Picture Of My Dog, Doc, To Reward You For Reading This Far'
              label='Doc'
              path='/doc'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;
