
import React from 'react';
import '../../App.css';
import Cards from '../Projects';
import Footer from '../Footer';


function Projects() {
    return (
      <>
        <Cards />
        <Footer />
      </>
    );
  }
  
  export default Projects;
